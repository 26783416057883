<template>
  <div v-if="auth">
    <v-dialog v-model="mapActive">
      <iframe
        class="frame"
        :src="mapSrc"
        width="910"
        height="720"
        frameborder="0"
      ></iframe>
    </v-dialog>
    <v-dialog v-model="colorActive" max-width="min-content">
      <div :class="colorClass"></div>
    </v-dialog>
    <v-dialog v-model="dishInfoActive">
      <div class="bg-white padding-10">
        <h2>{{ dishInfoTitle }}</h2>
        <div class="space-top-10">{{ dishInfoDesc }}</div>
      </div>
    </v-dialog>
    <div class="block">
      <v-parallax :srcset="image" :height="windowHeight" style="height: 100vh">
        <div class="inner-block vertical-layout bg-shaded">
          <div class="layout-center center">
            <img src="/script.png" class="deco-img" />
          </div>
          <v-icon class="layout-center center big font-black"
            >mdi-chevron-down</v-icon
          >
        </div>
      </v-parallax>
    </div>
    <div class="block vertical-layout bg-pink">
      <div class="layout-space-025">
        <div class="item center">
          <h1>{{ guest.welcome }}</h1>
          <div class="max-600">
            Мы рады сообщить о том, что
            {{ guest.date }} состоится {{ guest.type }} мероприятие, посвященное
            нашей свадьбе. С огромным удовольствием приглашаем разделить с нами
            этот особенный день!
          </div>
        </div>
        <div v-if="guest.official" class="item center">
          <h2>Регистрация</h2>
          <div v-if="!guest.main">(По желанию)</div>
          <div class="horizontal-layout center">
            <v-icon>mdi-clock</v-icon>
            <div>07.07.2023 14:45</div>
          </div>
          <div class="horizontal-layout center">
            <v-icon>mdi-map</v-icon>
            <div>ЗАГС Железнодорожного района</div>
          </div>
          <div>Братьев Быковых, 7</div>
          <v-btn x-small @click="showMap('official')">Показать на карте</v-btn>
        </div>
        <div v-if="guest.main" class="item center">
          <h2>Торжество</h2>
          <div class="horizontal-layout center">
            <v-icon>mdi-clock</v-icon>
            <div>07.07.2023 16:00</div>
          </div>
          <div class="horizontal-layout center">
            <v-icon>mdi-map</v-icon>
            <div>Отель "Tenet", Хохрякова, 1а</div>
          </div>
          <v-btn x-small @click="showMap('main')">Показать на карте</v-btn>
        </div>
        <div v-if="guest.party" class="item center">
          <h2>Вечернинка</h2>
          <div class="horizontal-layout center">
            <v-icon>mdi-clock</v-icon>
            <div>08.07.2023 17:00</div>
          </div>
          <div class="horizontal-layout center">
            <v-icon>mdi-map</v-icon>
            <div>P.L. Bar, Хохрякова, 24</div>
          </div>
          <v-btn x-small @click="showMap('party')">Показать на карте</v-btn>
          <div class="space-top-10">Цвета вечеринки</div>
          <div class="horizontal-layout center">
            <div class="color-box color1 mini-item" @click="showColor(1)"></div>
            <div class="color-box color2 mini-item" @click="showColor(2)"></div>
            <div class="color-box color3 mini-item" @click="showColor(3)"></div>
            <div class="color-box color4 mini-item" @click="showColor(4)"></div>
            <div class="color-box color5 mini-item" @click="showColor(5)"></div>
          </div>
          <div
            class="vertical-layout center outlined space-top-10"
            :style="menu1Style"
          >
            <div class="space-top-10">
              {{ guest.pair ? "Меню для первого гостя" : "Меню" }}
            </div>
            <div class="horizontal-layout center">
              <v-select
                class="max-300 layout-center"
                label="Салат"
                :items="salads"
                v-model="guest.salad1"
                @change="chooseDish('salad1')"
                ><template v-slot:item="{ item }">
                  <div class="horizontal-layout">
                    <div>{{ item }}</div>
                    <v-icon class="margin-left-10" @click="showDishInfo(item)"
                      >mdi-help-circle-outline</v-icon
                    >
                  </div>
                </template></v-select
              >
            </div>
            <div class="horizontal-layout center">
              <v-select
                class="max-300 layout-center"
                label="Горячее"
                :items="hotDishes"
                v-model="guest.hotDish1"
                @change="chooseDish('hotDish1')"
              ></v-select>
            </div>
            <div class="horizontal-layout center">
              <v-select
                class="max-300 layout-center"
                label="С чего начнем?"
                :items="drinks"
                v-model="guest.drink1"
                @change="chooseDish('drink1')"
              ></v-select>
            </div>
            <div class="horizontal-layout center">
              <div>&nbsp;</div>
              <div>{{ menu1Result }}</div>
            </div>
          </div>
          <div
            v-if="guest.pair"
            class="vertical-layout center outlined space-top-10"
            :style="menu2Style"
          >
            <div class="space-top-10">Меню для второго гостя</div>
            <div class="horizontal-layout center">
              <v-select
                class="max-300 layout-center"
                label="Салат"
                :items="salads"
                v-model="guest.salad2"
                @change="chooseDish('salad2')"
                ><template v-slot:item="{ item }">
                  <div class="horizontal-layout">
                    <div>{{ item }}</div>
                    <v-icon class="margin-left-10" @click="showDishInfo(item)"
                      >mdi-help-circle-outline</v-icon
                    >
                  </div>
                </template></v-select
              >
            </div>
            <div class="horizontal-layout center">
              <v-select
                class="max-300 layout-center"
                label="Горячее"
                :items="hotDishes"
                v-model="guest.hotDish2"
                @change="chooseDish('hotDish2')"
              ></v-select>
            </div>
            <div class="horizontal-layout center">
              <v-select
                class="max-300 layout-center"
                label="С чего начнем?"
                :items="drinks"
                v-model="guest.drink2"
                @change="chooseDish('drink2')"
              ></v-select>
            </div>
            <div class="horizontal-layout center">
              <div>&nbsp;</div>
              <div>{{ menu2Result }}</div>
            </div>
          </div>
        </div>
        <div class="layout-space-010">
          <div class="item center">
            <h2>Получится ли у вас посетить мероприятие?</h2>
          </div>
          <div class="item center">
            <v-btn small :color="buttonYesColor" @click="chooseYes"
              >Да, конечно</v-btn
            >
          </div>
          <div v-if="guest.allowP1" class="item center">
            <v-btn small :color="buttonP1Color" @click="chooseP1"
              >Да, конечно, приду +1</v-btn
            >
          </div>
          <div class="item center">
            <v-btn small :color="buttonNoColor" @click="chooseNo"
              >К сожалению, не получится</v-btn
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Home",
  data: () => ({
    auth: false,
    image: "",
    mapActive: false,
    colorActive: false,
    colorClass: "",
    dishInfoActive: false,
    dishInfoTitle: "",
    dishInfoDesc: "",
    mapSrc: "",
    salads: [
      "Салат с тунцом",
      "Сицилийский салат из томатов",
      "Салат с ростбифом",
    ],
    hotDishes: [
      "Сливочная паста с тунцом",
      "Лазанья болоньез",
      "Горячий ростбиф",
    ],
    drinks: [
      "Негрони",
      "Лонг Айленд",
      "Апероль Спритц",
      "Мартини фиеро",
      "Джин тоник",
      "Красное сухое вино",
      "Белое сухое вино",
      "Просекко",
      "Пиво разливное светлое",
    ],
    dishInfo: {
      "Салат с тунцом":
        "Томаты, микс салатов, фасоль стручковая, авокадо, пашот, огурец, тунец, картофель бейби, горчичный соус, сибулет, красный лук",
      "Сицилийский салат из томатов":
        "Микс салатов, черри, вяленые томаты, томаты, оливки, брынза, красный лук, базилик, черный перец, заправка масло вяленых томатов",
      "Салат с ростбифом": "Ростбиф, картофель бэйби, томаты, горчичный соус",
    },
    guest: {
      pair: false,
      welcome: "",
      official: false,
      main: false,
      party: false,
      allowP1: false,
      choice: null,
      salad1: null,
      hotDish1: null,
      drink1: null,
      salad2: null,
      hotDish2: null,
      drink2: null,
      date: "",
      type: "",
    },
    windowHeight: window.innerHeight,
    buttonYesColor: "default",
    buttonP1Color: "default",
    buttonNoColor: "default",
    menu1Style: {
      backgroundColor: "#ffffff",
    },
    menu2Style: {
      backgroundColor: "#ffffff",
    },
    menu1Result: "",
    menu2Result: "",
    animationTimer1: 0,
    animationTimer2: 0,
    maps: {
      official:
        "3A10c6bb964fcf5d28896e7a01f11449666a21ae418e18197eb07e6cb41fcec06c",
      main: "3A05ac321e33f1002445afdba44b608b7acd5c1d97fa2bfce47261e1b9922c71a5",
      party:
        "3A4e749a3f970af95314ce6b036fa8efeab61250b916db847ef892183df0fbbabd",
    },
  }),
  methods: {
    async loadGuest() {
      if (this.$route.query.key) {
        localStorage.setItem("key", this.$route.query.key);
      }

      const key = localStorage.getItem("key");
      if (key) {
        const response = await axios.get(`/api/key/${key}`);
        if (response.data.guest) {
          const guest = response.data.guest;
          this.auth = true;
          this.guest.pair =
            "pair" in guest ? Boolean(guest.pair) : guest.choice == 2;
          this.guest.welcome = guest.welcome;
          this.guest.official = guest.official;
          this.guest.main = guest.main;
          this.guest.party = guest.party;
          this.guest.allowP1 = guest.allowP1;
          this.guest.choice = guest.choice;
          this.guest.salad1 = guest.salad1 || null;
          this.guest.hotDish1 = guest.hotDish1 || null;
          this.guest.drink1 = guest.drink1 || null;
          this.guest.salad2 = guest.salad2 || null;
          this.guest.hotDish2 = guest.hotDish2 || null;
          this.guest.drink2 = guest.drink2 || null;

          if (this.guest.choice !== null) {
            if (this.guest.choice == 1) {
              this.updateYesColor();
            } else if (this.guest.choice == 2) {
              this.updateP1Color();
            } else {
              this.updateNoColor();
            }
          }

          if (this.guest.official || this.guest.main) {
            this.guest.date = "07.07.2023";
            this.guest.type = "торжественное";
          } else if (this.guest.party) {
            this.guest.date = "08.07.2023";
            this.guest.type = "праздничное";
          } else {
            this.guest.date = "07.07.2023";
            this.guest.type = "торжественное";
          }
        }
      }
    },
    async chooseYes() {
      const key = localStorage.getItem("key");
      await axios.post(`/api/yes/${key}`);
      this.updateYesColor();
    },
    async chooseP1() {
      const key = localStorage.getItem("key");
      await axios.post(`/api/p1/${key}`);
      this.updateP1Color();
    },
    async chooseNo() {
      const key = localStorage.getItem("key");
      await axios.post(`/api/no/${key}`);
      this.updateNoColor();
    },
    async chooseDish(dishTag) {
      const key = localStorage.getItem("key");
      const dishChoice = this.guest[dishTag];
      const dishNum = dishTag.endsWith("2") ? 2 : 1;
      try {
        await axios.post(`/api/dish/${key}/${dishTag}/${dishChoice}`);
        if (dishNum == 1) {
          this.playAnimation1(true);
          this.menu1Result = "Выбор сохранен";
        }
        if (dishNum == 2) {
          this.playAnimation2(true);
          this.menu2Result = "Выбор сохранен";
        }
      } catch (err) {
        if (dishNum == 1) {
          this.playAnimation1(false);
          this.menu1Result = "Ошибка сохранения";
        }
        if (dishNum == 2) {
          this.playAnimation2(false);
          this.menu2Result = "Ошибка сохранения";
        }
      }
    },
    showDishInfo(dish) {
      this.dishInfoActive = true;
      this.dishInfoTitle = dish;
      this.dishInfoDesc = this.dishInfo[dish];
    },
    updateYesColor() {
      this.buttonYesColor = "#7F7";
      this.buttonP1Color = "default";
      this.buttonNoColor = "default";
    },
    updateP1Color() {
      this.buttonYesColor = "default";
      this.buttonP1Color = "#7F7";
      this.buttonNoColor = "default";
    },
    updateNoColor() {
      this.buttonYesColor = "default";
      this.buttonP1Color = "default";
      this.buttonNoColor = "#F77";
    },
    playAnimation1(success) {
      clearInterval(this.animationTimer1);
      console.log("Animation1 start");

      let ticksLeft = 100;

      this.animationTimer1 = setInterval(() => {
        const color = 255 - ticksLeft--;

        if (success) {
          this.menu1Style.backgroundColor = `#${color.toString(
            16
          )}ff${color.toString(16)}`;
        } else {
          this.menu1Style.backgroundColor = `#ff${color.toString(
            16
          )}${color.toString(16)}`;
        }

        if (ticksLeft <= 0) {
          clearInterval(this.animationTimer1);
          console.log("Animation1 end");
        }
      }, 30);
    },
    playAnimation2(success) {
      clearInterval(this.animationTimer2);
      console.log("Animation2 start");

      let ticksLeft = 100;

      this.animationTimer2 = setInterval(() => {
        const color = 255 - ticksLeft--;

        if (success) {
          this.menu2Style.backgroundColor = `#${color.toString(
            16
          )}ff${color.toString(16)}`;
        } else {
          this.menu2Style.backgroundColor = `#ff${color.toString(
            16
          )}${color.toString(16)}`;
        }

        if (ticksLeft <= 0) {
          clearInterval(this.animationTimer2);
          console.log("Animation2 end");
        }
      }, 30);
    },
    showMap(type) {
      this.mapSrc = `https://yandex.ru/map-widget/v1/?um=constructor%${this.maps[type]}&amp;source=constructor`;
      this.mapActive = true;
    },
    showColor(index) {
      this.colorClass = `color-box-full color${index}`;
      this.colorActive = true;
    },
    updateBackgroundImage() {
      const ratio = window.innerWidth / window.innerHeight;
      if (ratio > 1) {
        console.log("Landscape");
        this.image =
          "/1920w-4.jpg 1920w, /1280w-4.jpg 1280w, /960w-4.jpg 960w, /720w-4.jpg 720w";
      } else {
        console.log("Portrait");
        this.image = "/1280h-4.jpg 1280w, /960h-4.jpg 960w, /720h-4.jpg 720w";
      }
    },
  },
  async created() {
    await this.loadGuest();
  },
  mounted() {
    this.windowHeight = window.innerHeight;
    this.updateBackgroundImage();
  },
};
</script>

<style scoped>
.big {
  font-size: 60px;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 18px;
}

div {
  font-size: 16px;
}

.space-top-10 {
  margin-top: 10px;
}

.font-black {
  color: #000;
}

.bg-white {
  background-color: #fff;
}

.bg-pink {
  background-color: #fee;
}

.bg-shaded {
  background-color: rgba(255, 255, 255, 0.7);
}

.block {
  min-width: 100%;
  min-height: 100vh;
  max-width: 100vh;
}

.inner-block {
  min-width: 100%;
  min-height: 100vh;
}

.vertical-layout {
  min-width: 100%;
  display: flex;
  flex-direction: column;
}

.horizontal-layout {
  min-width: 100%;
  display: flex;
  flex-direction: row;
}

.layout-center {
  margin: auto;
}

.layout-space-025 {
  margin: 25vh auto auto auto;
}

.layout-space-010 {
  margin: 10vh auto auto auto;
}

.frame {
  max-height: 60vh;
}

.max-300 {
  max-width: 300px !important;
}

.max-600 {
  max-width: 600px;
}

.outlined {
  padding: 5px;
  border: 1px solid #000;
  border-radius: 5px;
}

.center {
  text-align: center;
  justify-content: center;
}

.color-box {
  padding: 20px;
  border: 5px solid #fff;
  border-radius: 5px;
  cursor: pointer;
}

.color-box-full {
  padding: 100px;
  border: 5px solid #fff;
  border-radius: 5px;
}

.color1 {
  background-color: #f6c4c3;
}

.color2 {
  background-color: #a87b78;
}

.color3 {
  background-color: #bcb7b4;
}

.color4 {
  background-color: #9da188;
}

.color5 {
  background-color: #446049;
}

.item {
  margin: 20px;
}

.mini-item {
  margin: 5px;
}

.padding-10 {
  padding: 10px;
}

.margin-left-10 {
  margin-left: 10px;
}

.deco-img {
  width: 100%;
  max-width: 500px;
  height: auto;
}
</style>
