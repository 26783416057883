<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App'
};
</script>

<style>
.v-parallax__content {
  padding: 0px !important;
}
</style>
